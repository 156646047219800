import React from 'react';
import '../styles/globalStyles.scss';
import '../styles/typography.scss';
import Footer from './footer';
import Header from './header';

export default function Layout({ children }) {
  let pathClass;
  if (window.location.pathname === '/') {
    pathClass = 'front--page';
  } else {
    pathClass = window.location.pathname
      .substring(1)
      .replace('/', '-')
      .toLowerCase()
      .concat('--page');
  }

  return (
    <>
      <div id="main-container" className={`container ${pathClass}`}>
        <Header />
        {children}
        <Footer />
      </div>
    </>
  );
}
