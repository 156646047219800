import React from 'react';
import { Link } from 'gatsby';
import logo from '../assets/logos/wbs-logo-white-ol.svg';
import logoVert from '../assets/logos/wbs-logo-vertical-white-ol.svg';
import Nav from './nav';

export default function Header() {
  return (
    <header>
      <div className="logo">
        <Link to="/">
          <img className="horizontal" src={logo} alt="WBS Logo" />
          <img className="vertical" src={logoVert} alt="WBS Logo" />
        </Link>
      </div>
      <div className="nav">
        <Nav />
      </div>
    </header>
  );
}
