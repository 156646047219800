import React from 'react';

export default function Footer() {
  return (
    <footer>
      <span className="footer-item">
        ©{new Date().getFullYear()} WBS Construction, Inc.
      </span>
      <span className="footer-item">
        503-452-9822 <span className="separator">|</span> P.O. Box 19046
        Portland, OR 97280
      </span>
      <span className="footer-item">CCB# 108690</span>
    </footer>
  );
}
