import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const NavStyles = styled.nav`
  a,
  a:visited,
  a:active {
    color: var(--white);
    text-decoration: none;
    text-transform: uppercase;
  }
  // @todo move styles from global.
`;

export default function Nav() {
  return (
    <NavStyles>
      <ul>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/portfolio">Portfolio</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </NavStyles>
  );
}
