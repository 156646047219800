const React = require('react');
const Layout = require('./src/components/layout').default;

exports.onClientEntry = () => {
  window.addEventListener('load', () => {
    document.body.className = document.body.className.replace(/\bno-js\b/, '');
  });
};

exports.wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);
